import { ParticipantRoles } from '@breathelife/types';

export default {
  yes: 'Oui',
  no: 'Non',
  pdfTitle: {
    forInsuredName: 'Pour {{insuredName}}',
    lineOfBusiness: {
      annuity: {
        title: `Demande d'adhésion et de rente`,
      },
      life: {
        title: `Programme d’assurance personnalisé`,
      },
    },
  },
  advisorInformation: {
    header: 'Agent',
    name: "Nom d'Agent",
    number: "Numéro d'Agent",
    email: 'Courriel',
    phoneNumber: 'Numéro de téléphone',
  },
  productInformation: {
    coverageInformation: 'Information de la couverture',
    product: {
      one: 'Protection',
      other: 'Protections',
    },
    productDescription: 'Nom du produit',
    coverage: 'Couverture',
    monthlyPremium: 'Prime mensuelle',
    annualPremium: 'Prime annuelle',
    semiAnnualPremium: 'Prime semi-annuelle',
    quarterlyPremium: 'Prime trimestrielle',
    addonOptionPrefix: 'Option: ',
    addonDescription: 'Options additionnelles',
    includedAddon: 'Inclus',
    totalMonthlyPremium: 'Prime mensuelle totale',
    totalAnnualPremium: 'Prime annuelle totale',
    totalSemiAnnualPremium: 'Prime semi-annuelle totale',
    totalQuarterlyPremium: 'Prime trimestrielle totale',
    riderDescription: 'Nom du rider',
  },
  proposedInsuredInformation: {
    header: "Informations sur l'assuré proposé",
    fullName: 'Nom et prénom',
    title: 'Titre',
    firstName: 'Prénom',
    middleName: 'Deuxième nom',
    lastName: 'Nom de famille',
    suffix: 'Suffixe',
    gender: 'Genre',
    male: 'Homme',
    female: 'Femme',
    dateOfBirth: 'Date de naissance',
    smokerStatus: 'Statut de fumeur',
    smoker: 'Fumeur',
    nonSmoker: 'Non-fumeur',
    address: 'Adresse',
    phoneNumber: 'Numéro de téléphone',
    email: 'Email',
    previousFirstName: 'Prénom précédent',
    previousLastName: 'Nom de famille précédent',
    occupation: 'Occupation',
    canadianCitizenship: 'Citoyenneté canadienne',
    countryOfBirth: 'Pays de naissance',
    provinceOfBirth: 'Province de naissance',
    address2: 'Adresse 2',
    city: 'Ville',
    provinceOrState: 'Province', // FIXME: Determine province or state
    postalCode: 'Code postal',
    yes: 'Oui',
    no: 'Non',
  },
  annuitantInformation: {
    header: 'Informations sur le rentier',
    fullName: 'Nom et prénom',
    title: 'Titre',
    firstName: 'Prénom',
    middleName: 'Deuxième nom',
    lastName: 'Nom de famille',
    dateOfBirth: 'Date de naissance',
    gender: 'Genre',
    male: 'Homme',
    female: 'Femme',
    formerName: 'Nom précédent',
    email: 'Email',
    telephoneNumber: 'Numéro de téléphone',
    address: 'Adresse',
    address2: 'Adresse 2',
    city: 'Ville',
    province: 'Province',
    postalCode: 'Code postal',
  },
  signature: {
    header: 'Signatures',
    signatures: 'Signatures',
    type: {
      electronic: 'Clé de signature cryptée',
    },
    signBy: 'Signé par',
    [ParticipantRoles.AGENT]: {
      one: 'Conseiller',
      other: 'Conseillers',
    },
    [ParticipantRoles.INSURED]: {
      one: 'Assuré proposé',
      other: 'Assurés proposés',
    },
    [ParticipantRoles.OWNER_PERSON]: {
      one: 'Propriétaire',
      other: 'Propriétaires',
    },
    [ParticipantRoles.PAYER]: {
      one: 'Payeur',
      other: 'Payeurs',
    },
    [ParticipantRoles.SECONDARY_PAYER]: {
      one: 'Payeur',
      other: 'Payeurs',
    },
    [ParticipantRoles.LEGAL_GUARDIAN]: {
      one: 'Tuteur Légal',
      other: 'Tuteurs Légaux',
    },
    [ParticipantRoles.OFFICER]: {
      one: 'Agent',
      other: 'Agents',
    },
    [ParticipantRoles.SIGNING_AUTHORITY]: {
      one: 'Signataire Autorisé',
      other: 'Signataires Autorisés',
    },
    [ParticipantRoles.SOLE_PROPRIETOR]: {
      one: 'Seul Propriétaire',
      other: 'Seul Propriétaires',
    },
    [ParticipantRoles.TRANSLATOR]: {
      one: 'Traducteur',
      other: 'Traducteurs',
    },
    [ParticipantRoles.TRUSTEE]: {
      one: 'Fiduciaire',
      other: 'Fiduciaires',
    },
    [ParticipantRoles.ANNUITANT]: {
      one: 'Rentier',
      other: 'Rentiers',
    },
    [ParticipantRoles.CONTRIBUTOR]: {
      one: 'Contributeur',
      other: 'Contributeurs',
    },
    [ParticipantRoles.SECONDARY_CONTRIBUTOR]: {
      one: 'Contributeur',
      other: 'Contributeurs',
    },
    date: 'Date de signature',
    electronicInfo:
      'Je comprends que ma clé de signature cryptée crée une obligation juridique au même titre qu’une signature sur un document papier.',
    defaultLabel: {
      [ParticipantRoles.AGENT]: 'Signature du conseiller',
      [ParticipantRoles.INSURED]: `Signature de l'assuré`,
      [ParticipantRoles.OWNER_PERSON]: 'Signature du propriétaire',
      [ParticipantRoles.PAYER]: 'Signature du payeur',
      [ParticipantRoles.SECONDARY_PAYER]: 'Signature du payeur',
      [ParticipantRoles.LEGAL_GUARDIAN]: 'Signature du tuteur légal',
      [ParticipantRoles.OFFICER]: "Signature de l'agent",
      [ParticipantRoles.SIGNING_AUTHORITY]: 'Signature du signataire autorisé',
      [ParticipantRoles.SOLE_PROPRIETOR]: 'Signature du seul propriétaire',
      [ParticipantRoles.TRANSLATOR]: 'Signature du traducteur',
      [ParticipantRoles.TRUSTEE]: 'Signature du fiduciaire',
      [ParticipantRoles.ANNUITANT]: 'Signature du rentier',
      [ParticipantRoles.CONTRIBUTOR]: 'Signature du contributeur',
      [ParticipantRoles.SECONDARY_CONTRIBUTOR]: 'Signature du contributeur',
    },
    encrypted: 'Crypté',
  },
  submissionDate: 'Candidature complétée et déposée le: {{submissionDate}}',
  insuranceFirmInformation: {
    header: "Informations sur la compagnie d'assurance",
    name: 'Nom',
    address: 'Adresse postale',
    primaryPhoneNumber: 'Numéro de téléphone principal',
    tollFreePhoneNumber: 'Numéro de téléphone sans frais',
    email: 'Courriel',
    website: 'Site web',
  },
  payorInformation: {
    header: 'Information sur le payeur',
    fullName: 'Nom et prénom',
    title: 'Titre',
    firstName: 'Prénom',
    middleName: 'Deuxième nom',
    lastName: 'Nom de famille',
    suffix: 'Suffixe',
    address: 'Adresse',
    phoneNumber: 'Numéro de téléphone',
    email: 'Email',
    bankNumber: 'Numéro de banque',
    address2: 'Adresse 2',
    city: 'Ville',
    provinceOrState: 'Province', // FIXME: Determine province or state
    postalCode: 'Code postal',
  },
  ownerInformation: {
    header: 'Information sur le propriétaire',
    fullName: 'Nom et prénom',
    title: 'Titre',
    firstName: 'Prénom',
    middleName: 'Deuxième nom',
    lastName: 'Nom de famille',
    suffix: 'Suffixe',
    dateOfBirth: 'Date de naissance',
    address: 'Adresse',
    phoneNumber: 'Numéro de téléphone',
    email: 'Email',
    address2: 'Adresse 2',
    city: 'Ville',
    provinceOrState: 'Province', // FIXME: Determine province or state
    postalCode: 'Code postal',
    applicationCreatedOn: `Application créée le`,
    applicationCompletedOn: `Application complétée le`,
    insuredName: `Nom de l'assuré`,
    insuredEmail: `Courriel de l'assuré`,
    referenceNumber: `Numéro de référence`,
    id: `ID`,
  },
  carrierInformation: {
    applicationCreatedOn: `Application créée le`,
    applicationCompletedOn: `Application complétée le`,
    insuredName: `Nom de l'assuré`,
    insuredEmail: `Courriel de l'assuré`,
    referenceNumber: `Numéro de référence`,
    id: `ID`,
  },
};

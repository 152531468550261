import { ReactElement, Fragment } from 'react';

import { ProposedInsuredEntity } from '@breathelife/types';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { formatDateWithTime } from '../../../../helpers/date';
import { Header, DocumentTitle } from './Styles/PdfVariantBStyles';

export function CarrierInformation(): ReactElement {
  const { application, proposedInsuredInformation, timezone = 'America/Toronto' } = usePdfContext();
  const { submissionDate, createdAt, refNo, lang: language } = application;
  const {
    title: insuredTitle,
    firstName,
    middleName,
    lastName,
    suffix,
    email,
  } = proposedInsuredInformation || ({} as ProposedInsuredEntity);

  const fullName = [insuredTitle, firstName, middleName, lastName, suffix].filter(Boolean).join(' ');
  const pdfTitle = translate('pdfTitle.lineOfBusiness.life.title');

  return (
    <Fragment>
      <Header>
        <DocumentTitle>{pdfTitle}</DocumentTitle>
      </Header>
      {createdAt && (
        <p>
          <strong>{translate('carrierInformation.applicationCreatedOn', { locale: language })}</strong>:{' '}
          {formatDateWithTime(createdAt, language, timezone, false)}
        </p>
      )}
      {submissionDate && (
        <p>
          <strong>{translate('carrierInformation.applicationCompletedOn', { locale: language })}</strong>:{' '}
          {formatDateWithTime(submissionDate, language, timezone, false)}
        </p>
      )}
      <p>
        <strong>{translate('carrierInformation.insuredName', { locale: language })}</strong>: {fullName}
      </p>
      <p>
        <strong>{translate('carrierInformation.insuredEmail', { locale: language })}</strong>: {email}
      </p>
      <p>
        <strong>{translate('carrierInformation.referenceNumber', { locale: language })}</strong>: {refNo}
      </p>
      <p>
        <strong>{translate('carrierInformation.id', { locale: language })}</strong>:{' '}
        {application.private.userReportDigest}
      </p>
    </Fragment>
  );
}

import dayjs from 'dayjs';
import _ from 'lodash';
import { ReactElement, Fragment } from 'react';

import { Box, Grid } from '@breathelife/mui';
import { YesNoValue, ProposedInsuredEntity } from '@breathelife/types';
import { PdfFieldGroupHeader, PdfPaddedGridItem, PdfPaddedGrid } from '@breathelife/ui-components';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { QuestionAnswerGroupA } from './Styles/PdfQuestionAnswerGroupA';

export function ProposedInsuredInformation(): ReactElement | null {
  const { application, proposedInsuredInformation, useIndividualNameFields = false, isSmoker } = usePdfContext();
  const { lang: language } = application;

  const {
    title,
    firstName,
    middleName,
    lastName,
    suffix,
    dateOfBirth,
    gender,
    isTobaccoUser,
    previousFirstName,
    previousLastName,
    occupation,
    canadianCitizen,
    birthCountry,
    birthProvince,
    address,
    address2,
    city,
    provinceOrState,
    postalCode,
    contactTimeOfDay,
    tobaccoMostRecentUsage,
    marijuanaMostRecentUsage,
  } = proposedInsuredInformation || ({} as ProposedInsuredEntity);

  if (!firstName && !lastName) {
    return null;
  }

  const capitalizedTitle = title ? _.capitalize(title) : '';

  const fullName = [capitalizedTitle, firstName, middleName, lastName, suffix].filter(Boolean).join(' ');

  const smokerStatus: string = isSmoker({ isTobaccoUser, tobaccoMostRecentUsage, marijuanaMostRecentUsage })
    ? translate('proposedInsuredInformation.smoker', { locale: language })
    : translate('proposedInsuredInformation.nonSmoker', { locale: language });

  const canadianCitizenship: string =
    canadianCitizen && canadianCitizen === YesNoValue.yes
      ? translate('proposedInsuredInformation.yes', { locale: language })
      : translate('proposedInsuredInformation.no', { locale: language });

  return (
    <Box mb={3}>
      <PdfFieldGroupHeader data-testid='ProposedInsuredInformation'>
        {translate('proposedInsuredInformation.header', { locale: language })}
      </PdfFieldGroupHeader>

      <PdfPaddedGrid container spacing={3} alignItems='flex-start'>
        {useIndividualNameFields && (
          <Fragment>
            {!!capitalizedTitle && (
              <Grid item xs={4}>
                <QuestionAnswerGroupA
                  labelText={translate('proposedInsuredInformation.title', { locale: language })}
                  value={capitalizedTitle}
                ></QuestionAnswerGroupA>
              </Grid>
            )}
            {!!firstName && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  labelText={translate('proposedInsuredInformation.firstName', { locale: language })}
                  value={firstName}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!middleName && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  labelText={translate('proposedInsuredInformation.middleName', { locale: language })}
                  value={middleName}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!lastName && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  labelText={translate('proposedInsuredInformation.lastName', { locale: language })}
                  value={lastName}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!suffix && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  labelText={translate('proposedInsuredInformation.suffix', { locale: language })}
                  value={suffix}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
          </Fragment>
        )}
        {!useIndividualNameFields && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('proposedInsuredInformation.fullName', { locale: language })}
              value={fullName}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {gender && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('proposedInsuredInformation.gender', { locale: language })}
              value={translate(`proposedInsuredInformation.${gender.toLowerCase()}`, { locale: language })}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {dateOfBirth && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('proposedInsuredInformation.dateOfBirth', { locale: language })}
              value={dayjs.utc(dateOfBirth).format('YYYY-MM-DD')}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {isTobaccoUser && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('proposedInsuredInformation.smokerStatus', { locale: language })}
              value={smokerStatus}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!previousFirstName && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('proposedInsuredInformation.previousFirstName', { locale: language })}
              value={previousFirstName}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!previousLastName && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('proposedInsuredInformation.previousLastName', { locale: language })}
              value={previousLastName}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!occupation && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('proposedInsuredInformation.occupation', { locale: language })}
              value={occupation}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!canadianCitizen && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('proposedInsuredInformation.canadianCitizenship', { locale: language })}
              value={canadianCitizenship}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!birthCountry && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('proposedInsuredInformation.countryOfBirth', { locale: language })}
              value={birthCountry}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!birthProvince && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('proposedInsuredInformation.provinceOfBirth', { locale: language })}
              value={birthProvince}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!address && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('proposedInsuredInformation.address', { locale: language })}
              value={address}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!address2 && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('proposedInsuredInformation.address2', { locale: language })}
              value={address2}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!city && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('proposedInsuredInformation.city', { locale: language })}
              value={city}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!provinceOrState && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('proposedInsuredInformation.provinceOrState', { locale: language })}
              value={provinceOrState}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
        {!!postalCode && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA
              labelText={translate('proposedInsuredInformation.postalCode', { locale: language })}
              value={postalCode}
            ></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}

        {!!contactTimeOfDay && (
          <PdfPaddedGridItem item xs={4}>
            <QuestionAnswerGroupA labelText={'Time of Day'} value={contactTimeOfDay}></QuestionAnswerGroupA>
          </PdfPaddedGridItem>
        )}
      </PdfPaddedGrid>
    </Box>
  );
}

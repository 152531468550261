import { ReactElement, Fragment } from 'react';
import styled from 'styled-components';

import { Grid } from '@breathelife/mui';

import { CryptoStamp } from '../../../../Assets/Images/CryptoStamp';
import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { formatDate } from '../../../../helpers/date';
import { QuestionAnswerGroupA } from '../ComponentVariantsA/Styles/PdfQuestionAnswerGroupA';
import { P, PdfVariantFieldText, Title, Separator, RowSpacing } from './Styles/PdfVariantBStyles';

const CryptoSignatureContainer = styled.div`
  & {
    break-inside: avoid;
  }
`;

export function CryptoSignature(): ReactElement {
  const { application, proposedInsuredInformation, payorInformation = [], cryptoSignatureData } = usePdfContext();
  const { lang: language } = application;

  const signeeName: string = `${proposedInsuredInformation?.firstName ?? ''} ${
    proposedInsuredInformation?.lastName ?? ''
  }`.trim();

  const formattedDate = cryptoSignatureData?.date ? formatDate(cryptoSignatureData.date, language, true) : '';

  const payerSigneeName: string = `${payorInformation[0]?.firstName ?? ''} ${
    payorInformation[0]?.lastName ?? ''
  }`.trim();

  const payerType = application.answers.insuredPeople[0].payment.hasDifferentPayer;
  const cryptoSignatureDataValue = cryptoSignatureData?.signature ?? '';
  const payerIdentifier = cryptoSignatureData?.payerIdentifier ?? '';
  const cryptoPayerData = cryptoSignatureDataValue + payerIdentifier;

  return (
    <Fragment>
      <CryptoSignatureContainer>
        <Title>{translate('signature.signatures', { locale: language })}</Title>
        <Separator />
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={12}>
            <P>
              Je comprends que ma signature électronique cryptée crée une obligation juridique au même titre qu'une
              signature sur un document papier.
            </P>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <QuestionAnswerGroupA
                      labelText={translate('signature.type.electronic', { locale: language })}
                      value={cryptoSignatureData?.signature ?? ''}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <QuestionAnswerGroupA
                      labelText={translate('signature.INSURED', { locale: language, count: 1 })}
                      value={signeeName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <QuestionAnswerGroupA
                      labelText={translate('signature.date', { locale: language })}
                      value={formattedDate}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <PdfVariantFieldText>Assuré/Propriétaire</PdfVariantFieldText>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <CryptoStamp language={language} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <RowSpacing />
            </Grid>

            {typeof payerType !== 'undefined' && payerType !== 'false' && payerSigneeName && (
              <Grid container spacing={1}>
                <Fragment>
                  <Grid item xs={6}>
                    <RowSpacing />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <QuestionAnswerGroupA
                          labelText={translate('signature.type.electronic', { locale: language })}
                          value={cryptoPayerData ?? ''}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <QuestionAnswerGroupA
                          labelText={translate('signature.signBy', { locale: language, count: 1 })}
                          value={payerSigneeName}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <QuestionAnswerGroupA
                          labelText={translate('signature.date', { locale: language })}
                          value={formattedDate}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <PdfVariantFieldText>Payeur</PdfVariantFieldText>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={10}>
                      <Grid item xs={12} spacing={10}>
                        <CryptoStamp language={language} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <RowSpacing />
                  </Grid>
                </Fragment>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CryptoSignatureContainer>
    </Fragment>
  );
}
